import React, { useState, useContext } from "react"

import { navigate } from "gatsby"

import { AuthenticationContext } from "../../context/"

import "./ForgotPasswordComponent.scss"

const ForgotPasswordComponent = () => {
  const { forgotPassword, isLoadingRequest, setIsLoadingRequest } = useContext(
    AuthenticationContext
  )
  const [email, setEmail] = useState("")

  const handleInputChange = (e) => {
    setIsLoadingRequest(false)
    setEmail(e.target.value.toLowerCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    forgotPassword(email)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  const handleBack = () => {
    navigate("/")
  }

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-component">
        <div className="forgot-password-component__body">
          <div className="forgot-password-component__body__heading">
            <p>Forgot Password</p>
          </div>
          <div className="forgot-password-component__body__description">
            <p>
              Passwords are like socks, you’re bound to lose one or two on
              occasion. Enter your email below and follow the instructions
              detailed in our email. Thank you.
            </p>
          </div>
          <div className="forgot-password-component__body__email">
            <form id="forgot-form" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  placeholder="Please Enter"
                  value={email}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                  title="Please include a domain name in the email"
                />
              </div>
            </form>
          </div>
          <div className="forgot-password-component__body__submit">
            <button
              type="submit"
              form="forgot-form"
              disabled={isLoadingRequest}
            >
              Reset your password
            </button>
          </div>
          <div className="forgot-password-component__body__back">
            <button type="button" onClick={handleBack}>
              Back to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordComponent
