import React from "react"

import RegisterLayout from "../components/Layout/RegisterLayout"
import "../components/LoginRegister/Authenticate.scss"
import ForgotPasswordComponent from "../components/LoginRegister/ForgotPasswordComponent"

const ForgotPassword = () => {
  return (
    <RegisterLayout isFooter={true}>
      <ForgotPasswordComponent />
    </RegisterLayout>
  )
}

export default ForgotPassword
